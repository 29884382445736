<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <content-modal
      :id="item.id"
      :title="T('Web.DashboardPage.WidgetStore.Widgets.WidgetSettings')"
      body-class="p-0"
    >
      <settings-modal-inner :settings="localSettings" />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="updateWidgetSettings"
          >
            {{ T("Web.DashboardPage.WidgetStore.Widgets.SaveSettings") }}
          </b-button>
        </div>
      </template>
    </content-modal>
    <template v-slot:options>
      <b-dropdown-item href="#" @click="$bvModal.show(item.id)">
        {{ T("Web.DashboardPage.WidgetStore.Widgets.WidgetSettings") }}
        <feather-icon icon="SettingsIcon"
      /></b-dropdown-item>
    </template>
    <div class="inner">
      <div class="header">
        <b-card-title>{{ title }}</b-card-title>
        <b-card-sub-title>{{ showingPeriodeText }}</b-card-sub-title>
      </div>
      <div class="body">
        <span class="summed-amount">{{ summedAmount | toCurrency }}</span
        ><span class="font-weight-bold">{{ unit }}</span>
      </div>
    </div>
  </base-widget>
</template>

<script>
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
import BaseWidget from "../common/BaseWidget.vue";
import {
  BCardTitle,
  BCardSubTitle,
  BDropdownItem,
  BButton,
} from "bootstrap-vue";
import ContentModal from "@/app/common/modals/ContentModal.vue";
import SettingsModalInner from "./SettingsModalInner.vue";
import ResourceService from "@/services/base/resource.service";
export default {
  components: {
    BaseWidget,
    BButton,
    BCardTitle,
    BCardSubTitle,
    BDropdownItem,
    ContentModal,
    SettingsModalInner,
  },
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  async mounted() {
    await this.fetchData();
  },
  data() {
    return {
      summedAmount: 0,
      localSettings: JSON.parse(JSON.stringify(this.item.settings)),
    };
  },
  methods: {
    async fetchData() {
      let requestParams = {
        ...TimeSpanHelper.getFromAndToDateFromPeriodeScope(
          this.item.settings.periodeScope
        ),
        locationsFilter: this.item.settings.locationsFilter,
        wasteFractionsFilter: this.item.settings.wasteFractionsFilter,
      };
      const service = new ResourceService(`concern/statistics`);
      let uri = "";
      switch (this.dataSource) {
        case "IncomeBrutto":
        case "IncomeNetto":
          requestParams = {
            ...requestParams,
            showBruttoResult: this.dataSource == "IncomeBrutto",
          };
          uri = "financials/income";
          break;
        case "ChargeBrutto":
        case "ChargeNetto":
          requestParams = {
            ...requestParams,
            showBruttoResult: this.dataSource == "ChargeBrutto",
          };
          uri = "financials/expenses";
          break;
        case "WasteCollections":
          uri = "waste-collections/count";
          break;
        case "TonsWasteCollected":
          uri = "waste-collections/tons-collected";
          break;
        case "CubicWasteCollected":
          uri = "waste-collections/cubic-collected";
          break;
        case "CO2Emission":
          uri = "co2-emissions";
          break;
        case "CO2EmissionIncludingTransport":
          uri = "co2-emissions";
          requestParams = {
            ...requestParams,
            includeTransport: true,
          };
          break;
        case "KmDriven":
          uri = "transport/km-driven";
          break;
        case "HourlyConsumption":
          uri = "financials/hourly-consumption";
          break;
      }
      let response = await service.get(uri, requestParams);
      this.summedAmount = response.data.data.amount;
    },

    async updateWidgetSettings() {
      this.item.settings = JSON.parse(JSON.stringify(this.localSettings));
      await this.fetchData();
      this.$bvModal.hide(this.item.id);
    },
  },

  computed: {
    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;

      switch (this.dataSource) {
        case "IncomeNetto":
          return this.T("Web.DashboardPage.WidgetStore.Widgets.IncomeNetto");
        case "IncomeBrutto":
          return this.T("Web.DashboardPage.WidgetStore.Widgets.IncomeBrutto");
        case "ChargeBrutto":
          return this.T("Web.DashboardPage.WidgetStore.Widgets.ChargeBrutto");
        case "ChargeNetto":
          return this.T("Web.DashboardPage.WidgetStore.Widgets.ChargeNetto");
        case "WasteCollections":
          return this.T(
            "Web.DashboardPage.WidgetStore.Widgets.CollectionsCount",
            "Collections (amount)"
          );
        case "TonsWasteCollected":
          return this.T("Web.DashboardPage.WidgetStore.Widgets.TonsCollected");
        case "CubicWasteCollected":
          return this.T("Web.DashboardPage.WidgetStore.Widgets.CubicCollected");
        case "CO2Emission":
          return this.T("Web.DashboardPage.WidgetStore.Widgets.CO2Emitted");
        case "CO2EmissionIncludingTransport":
          return this.T(
            "Web.DashboardPage.WidgetStore.Widgets.CO2EmittedIncTransport"
          );
        case "KmDriven":
          return this.T(
            "Web.DashboardPage.WidgetStore.Widgets.KmDriven",
            "Km driven"
          );
        case "HourlyConsumption":
          return this.T(
            "Web.DashboardPage.WidgetStore.Widgets.HourlyConsumption",
            "Time consumption"
          );
        default:
          return "";
      }
    },
    showingPeriodeText() {
      return TimeSpanHelper.getOptionNameFromPeriodeScope(
        this.item.settings.periodeScope
      );
    },
    unit() {
      switch (this.dataSource) {
        case "IncomeBrutto":
        case "IncomeNetto":
        case "ChargeBrutto":
        case "ChargeNetto":
          return this.currencyScope;
        case "WasteCollections":
          return this.T("Web.Generic.Units.Pcs");
        case "TonsWasteCollected":
          return this.T("Web.Generic.Units.Tons");
        case "CubicWasteCollected":
          return this.T("Web.Generic.Units.Cubic");
        case "CO2Emission":
        case "CO2EmissionIncludingTransport":
          return this.T("Web.Generic.Units.Tons");
        case "KmDriven":
          return this.T("Web.Generic.Units.Km");
        case "HourlyConsumption":
          return this.T("Web.Generic.Units.Hours");
      }
    },
    dataSource() {
      return this.item.settings.dataSource;
    },
  },
};
</script>

<style lang="scss" scoped>
.inner {
  display: flex;
  flex-direction: column;
  height: 100%;

  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    .summed-amount {
      padding: 0px 20px;
      font-size: 3em;
      font-weight: 200;
      color: #407e3d;
    }
  }
}

@media screen and (max-width: 1400px) {
  .inner {
    .body {
      .summed-amount {
        font-size: 2em;
      }
    }
  }
}
</style>