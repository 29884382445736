<template>
  <base-widget @remove-clicked="$emit('remove-clicked')" :edit-mode="editMode">
    <content-modal
      :id="item.id"
      :title="T('Web.DashboardPage.WidgetStore.Widgets.WidgetSettings')"
      body-class="p-0"
    >
      <settings-modal-inner :settings="localSettings" />
      <template v-slot:modal-footer>
        <div class="w-100">
          <b-button
            variant="secondary"
            class="float-right"
            @click="updateWidgetSettings"
          >
            {{ T("Web.DashboardPage.WidgetStore.Widgets.SaveSettings") }}
          </b-button>
        </div>
      </template>
    </content-modal>
    <template v-slot:options>
      <b-dropdown-item href="#" @click="$bvModal.show(item.id)">
        {{ T("Web.DashboardPage.WidgetStore.Widgets.WidgetSettings") }}
        <feather-icon icon="SettingsIcon"
      /></b-dropdown-item>
    </template>

    <div class="inner">
      <div class="header">
        <b-card-title>{{ title }}</b-card-title>
        <b-card-sub-title>{{ showingPeriodeText }}</b-card-sub-title>
      </div>
      <div class="body">
        <b-table
          :fields="tableFields"
          :items="orders"
          show-empty
          :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
          small
        >
          <template #cell(ordere)="data">
            {{ data.item.orderer.name }}<br />
            <small>{{ format_datetime(data.item.orderDate) }}</small>
          </template>
          <template #cell(container)="data">
            <span class="font-weight-bold"
              >{{ data.item.orderItem.name }}
            </span>
            <small> {{ data.item.orderItem.refId }}</small
            ><br />
            <small
              >{{ data.item.pickupLocation.zip }}
              {{ data.item.pickupLocation.city }},
              {{ data.item.pickupLocation.address }} -
              {{ data.item.pickupLocation.name }}</small
            >
          </template>
          <template #cell(kgWasteCollected)="data">
            <b-form-input type="number" size="sm" v-model="data.item.kgWasteCollected" @change="updateOrderKgWasteCollected(data.item)" />
          </template>
          <template #cell(status)="data">
            <small>
              <span v-if="data.item.status == 'Created'">
                {{ T("Web.Generic.Ordering.Status.NotApproved") }}
              </span>
              <span v-else-if="data.item.status == 'Accepted'">
                {{ T("Web.Generic.Ordering.Status.Accepted") }}<br />
                <small>{{ format_datetime(data.item.acceptedAt) }}</small>
              </span>
              <span v-else-if="data.item.status == 'Canceled'"
                >{{ T("Web.Generic.Ordering.Status.Cancled") }}}}<br />
                <small>{{ format_datetime(data.item.canceledAt) }}</small>
              </span>
              <span v-else>
                {{ T("Web.Generic.Ordering.Status.Emptied") }}<br />
                <small>{{ format_datetime(data.item.completedAt) }}</small>
              </span>
            </small>
          </template>
        </b-table>
        <div class="text-center font-weight-bold mt-1 mb-1 text-primary">
          <a
            href="#"
            v-if="pageInfo && pageInfo.nextPage > 0"
            @click.prevent="fetchNextPageOfOrders"
            >{{ T("Web.Generic.FetchMore", "Fetch more") }}
            <feather-icon icon="ChevronDownIcon"
          /></a>
        </div>
      </div>
    </div>
  </base-widget>
</template>

<script>
import { TimeSpanHelper } from "@/app/concern-app/utils/timespanHelper";
import BaseWidget from "../common/BaseWidget.vue";
import {
  BCardTitle,
  BCardSubTitle,
  BTable,
  BDropdownItem,
  BButton,
  BFormInput,
} from "bootstrap-vue";
import ContentModal from "@/app/common/modals/ContentModal.vue";
import SettingsModalInner from "./SettingsModalInner.vue";
import ResourceService from "@/services/base/resource.service";
import ordering from '@/router/routes/ordering';
export default {
  props: {
    item: {
      required: true,
    },
    dashboardId: {
      required: true,
    },
    editMode: {
      required: true,
    },
  },
  components: {
    BaseWidget,
    BCardTitle,
    BCardSubTitle,
    BDropdownItem,
    BFormInput,
    BButton,
    BTable,
    ContentModal,
    SettingsModalInner,
  },
  async created() {
    await this.fetchData();
  },
  data() {
    return {
      loading: true,
      pageInfo: undefined,
      localSettings: JSON.parse(JSON.stringify(this.item.settings)),
      orders: [],
    };
  },
  computed: {
    title() {
      if (
        this.item.settings.customTitle &&
        this.item.settings.customTitle != ""
      )
        return this.item.settings.customTitle;

      return this.T(
        "Web.DashboardPage.WidgetStore.Widgets.ConcernOrdersOverviewWidget.Title",
        "Order overview"
      );
    },
    showingPeriodeText() {
      return TimeSpanHelper.getOptionNameFromPeriodeScope(
        this.item.settings.periodeScope
      );
    },
    tableFields() {
      return [
        {
          key: "requisitionId",
          label: this.T("Web.Generic.Ordering.RequisitionId"),
          sortable: true,
        },
        {
          key: "ordere",
          label: this.T("Web.Generic.Ordering.Orderer"),
          sortable: true,
        },
        {
          key: "container",
          label: this.T("Web.Generic.Ordering.Container", "Container"),
          sortable: true,
        },
        {
          key: "orderSupplier.name",
          label: this.T("Web.Generic.Ordering.Supplier", "Supplier"),
          sortable: true,
        },
        {
          key: "kgWasteCollected",
          label: this.T(
            "Web.Generic.Ordering.KgWasteCollected",
            "Kg waste collected"
          ),
          sortable: true,
        },
        {
          key: "status",
          label: this.T("Web.Generic.Status"),
          sortable: true,
        },
      ];
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      var result = await this.getResult(1, 10000);
      this.orders = result.data.items;
      this.pageInfo = result.data.paging;
      this.fetching = false;
    },
    async fetchNextPageOfOrders() {
      var result = await this.getResult(
        this.pageInfo.nextPage,
        this.pageInfo.perPage
      );
      this.orders = [...this.orders, ...result.data.items];
      this.pageInfo = result.data.paging;
    },
    async updateWidgetSettings() {
      this.item.settings = JSON.parse(JSON.stringify(this.localSettings));
      await this.fetchData();
      this.$bvModal.hide(this.item.id);
    },

    async getResult(page, perPage) {
      let requestParams = {
        ...TimeSpanHelper.getFromAndToDateFromPeriodeScope(
          this.item.settings.periodeScope
        ),
        locationsFilter: this.item.settings.locationsFilter,
        page: page,
        perPage: perPage,
      };
      const service = new ResourceService("concern/orders");
      return (await service.get("", requestParams)).data;
    },
    async updateOrderKgWasteCollected(orderItem)
    {
      const service = new ResourceService("concern/orders");
      await service.put(`${orderItem.id}/kg-collected`, {kgWasteCollected: orderItem.kgWasteCollected})
    }
  },
};
</script>

<style lang="scss" scoped>
.inner {
  height: 100%;
  .body {
    overflow-y: auto;
    height: calc(100% - 59px);

    margin-top: 20px;
  }
}
</style>